const links = [
  {
    path: "#about",
    text: "about",
    title: "About Me",
  },
  {
    path: "#projects",
    text: "projects",
    title: "Projects",
  },
  {
    path: "#contact",
    text: "contact",
    title: "Contact Me",
  },
]

export default links
